import './App.css';
import CocaInfos from './Components/CocaInfos';
import Form from './Components/Form';
import Success from './Components/Success';
import Support from './Components/Support';
import { useForm } from './Utils/FormContext';

const App = () => {
  const { answered } = useForm();
  return (
    <>
      {answered ? <Success /> :
        <>
          <CocaInfos />
          <Form />
          <Support />
        </>
      }
    </>
  );
}

export default App;
