const GENDER_HELPERS: any = {
  'Feminino': 'Mulher Cis',
  'Masculino': 'Homem Cis',
  'Mulher transgênero': '',
  'Homem transgênero': '',
  'Gênero não-binário': 'Não binárie',
  'Agênero': 'Agênero',
  'Prefiro não informar': 'Não declarar',
  'Outros': '',
};

const STATE_HELPERS: any = {
  "Acre": "AC",
  "Alagoas": "AL",
  "Amapá": "AP",
  "Amazonas": "AM",
  "Bahia": "BA",
  "Ceará": "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES",
  "Goiás": "GO",
  "Maranhão": "MA",
  "Mato Grosso": "MT",
  "Mato Grosso do Sul": "MS",
  "Minas Gerais": "MG",
  "Pará": "PA",
  "Paraíba": "PB",
  "Paraná": "PR",
  "Pernambuco": "PE",
  "Piauí": "PI",
  "Rio de Janeiro": "RJ",
  "Rio Grande do Norte": "RN",
  "Rio Grande do Sul": "RS",
  "Rondônia": "RO",
  "Roraima": "RR",
  "Santa Catarina": "SC",
  "São Paulo": "SP",
  "Sergipe": "SE",
  "Tocantins": "TO"
};

const LEVEL_EDUCATION_HELPERS: any = {
  'ens-comp-inc': '',
  'ens-comp-curs': '',
  'ens-comp-comp': '',
  'ens-fund-inc': '',
  'ens-fund-curs': 'Ensino Fundamental Cursando',
  'ens-fund-comp': 'Ensino Fundamental Completo',
  'ens-med-inc': '',
  'ens-med-curs': 'Ensino Médio Cursando',
  'ens-med-comp': 'Ensino Médio Completo',
  'tec-med-inc': '',
  'tec-med-curs': '',
  'tec-med-comp': '',
  'ens-sup-inc': '',
  'ens-sup-curs': 'Ensino Superior Cursando',
  'ens-sup-comp': 'Ensino Superior Completo',
};

const DISABILITIES_HELPERS: any = {
  'hearing': 'Auditiva',
  'phisic': 'Física',
  'mental': 'Intelectual',
  'visual': 'Visual',
  'psychosocial': 'Psicossocial'
};

const loadUserIntegrationInfo = (form: any, data: any) => {
  if (data.fullName) {
    form['nome'] = data.fullName;
  }

  if (data.email) {
    form['email'] = data.email;
  }

  if (data.cellphone) {
    form['telefone'] = data.cellphone;
  }

  if (data.birthdate) {
    const date: Date = new Date(data.birthdate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    form['data_nascimento'] = `${year}-${month}-${day}`;
  }

  if (data.cpf) {
    form['cpf'] = data.cpf;
  }

  if (data.gender) {
    form['genero'] = GENDER_HELPERS[data.gender];
  }

  if (data.state) {
    form['estado'] = STATE_HELPERS[data.state];
  }

  if (data.city) {
    form['cidade'] = data.city;
  }

  if (data.district) {
    form['bairro'] = data.district;
  }

  if (data.street) {
    form['rua'] = data.street;
  }

  if (data.zipCode) {
    form['cep'] = data.zipCode.slice(0, 5) + '-' + data.zipCode.slice(5);
  }

  if (data.levelEducation) {
    form['escolaridade'] = LEVEL_EDUCATION_HELPERS[data.levelEducation];
  }

  const disabilities: string[] = data?.disabilities?.split(',') || [];

  if (disabilities.length) {
    const deficiencia: string[] = [];

    disabilities.forEach((type) => {
      if (DISABILITIES_HELPERS[type]) {
        deficiencia.push(DISABILITIES_HELPERS[type]);
      }
    })

    if (!deficiencia.length) {
      deficiencia.push('none');
    }

    form['deficiencia'] = deficiencia;
  }

  return form;
};

const taqeAppIntegration: any = {
  GENDER_HELPERS,
  STATE_HELPERS,
  LEVEL_EDUCATION_HELPERS,
  DISABILITIES_HELPERS,
  loadUserIntegrationInfo
}

export default taqeAppIntegration;
